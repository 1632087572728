/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { RefObject } from 'react'
import styles from '../app/styles/Video.module.sass'
import { Pause } from './Pause.svg'
import { Play } from './Play.svg'

type VideoProps = {
	url?: string
	poster?: string
}

export function Video(props: VideoProps) {
	const [videoPlaying, setVideoPlaying] = React.useState<boolean>(false)
	const videoRef = React.useRef() as RefObject<HTMLVideoElement>
	const handlePlay = () => {
		if (videoRef.current) {
			const isPlaying =
				!videoRef.current?.paused && !videoRef.current?.ended && videoRef.current?.currentTime > 0
			if (isPlaying) {
				videoRef.current.pause()
				setVideoPlaying(false)
			} else {
				videoRef.current.play()
				setVideoPlaying(true)
			}
		}
	}

	React.useEffect(() => {
		const handleVideoEnd = () => {
			setVideoPlaying(false)
		}

		videoRef.current?.addEventListener('ended', handleVideoEnd)
		videoRef.current?.addEventListener('pause', handleVideoEnd)
		return () => {
			videoRef.current?.removeEventListener('ended', handleVideoEnd)
			videoRef.current?.removeEventListener('pause', handleVideoEnd)
		}
	}, [videoRef, setVideoPlaying])

	return (
		<div className={styles.video}>
			<button onClick={handlePlay} className={videoPlaying ? styles.playing : ''}>
				{videoPlaying && <Pause />}
				{!videoPlaying && <Play />}
			</button>
			<video ref={videoRef} poster={props.poster}>
				<source src={props.url} type="video/mp4" />
			</video>
		</div>
	)
}
