import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React, { RefObject } from 'react'
import styles from '../app/styles/PersonPopup.module.sass'
import type { MediaResult } from '../queries/MediaFragment'
import { zalomeni } from '../utils/zalomeni'
import { ContemberImage } from './ContemberImage'
import { Crosss } from './Cross.svg'
import { Video } from './Video'

type PersonPopupProps = {
	media: MediaResult
	name?: string
	position?: string
	description?: string
	index: number
	isActive: boolean
	setPopup: React.Dispatch<React.SetStateAction<number | null>>
}

export function PersonPopup(props: PersonPopupProps) {
	const { isActive, setPopup } = props

	const ref = React.useRef() as RefObject<HTMLDivElement>

	React.useEffect(() => {
		if (isActive) {
			const handleClickOutside = (event: MouseEvent) => {
				const clickedEl = event.target as Node | null
				if (ref.current && !ref.current.contains(clickedEl)) {
					setPopup(null)
				}
			}
			document.addEventListener('mousedown', handleClickOutside)

			return () => {
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}
	}, [ref, setPopup, isActive])

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div
			className={clsx(
				styles.popup,
				props.isActive && styles.active,
				!props.description && styles.onlyImage
			)}
			data-index={props.index}
			data-close>
			<div className={styles.wrap}>
				<button
					data-close
					onClick={() => {
						props.setPopup(null)
					}}
					className={styles.close}>
					<Crosss />
				</button>
				<div className={styles.inner} ref={ref}>
					{props.description && (
						<div className={styles.content}>
							<h2>{props.name}</h2>
							<article>
								{props.position && <p>{props.position}</p>}
								<RichTextRenderer source={zalomeni(props.description)} />
							</article>
						</div>
					)}
					{props.media && (
						<div className={styles.image}>
							{props.media.type == 'video' && props.isActive && (
								<Video poster={props.media.poster} url={props.media.url} />
							)}
							<ContemberImage
								image={{
									id: props.media.id,
									url: props.media.poster ?? '',
									height: props.media.height,
									width: props.media.width,
								}}
								objectFit={'cover'}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
