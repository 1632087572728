/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/CtaMultiple.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Cta } from '../Cta'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ctaMultiple = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<div className={styles.bg}>
				{reference.image && <ContemberImage image={reference.image} objectFit="cover" />}
			</div>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<div className={styles.inner}>
					{reference.ctaList?.items.map((item) => (
						<Cta
							headline={item.title ? zalomeni(item.title) : ''}
							desc={item.desc ? item.desc : ''}
							link={item.link}
							key={item.id}
						/>
					))}
				</div>
			</Container>
		</section>
	)
}
