/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import styles from '../../app/styles/Benefits.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { BenefitList } from '../BenefitList'
import { Container } from '../Container'

export const benefits = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<div className={styles.inner}>
					{reference.benefitList?.items.map((item, index) => (
						<div key={item.id} className={styles.single}>
							<span>{index + 1}</span>
							<article>
								<h3>{item.title}</h3>
								<BenefitList items={item.bullets} />
							</article>
						</div>
					))}
				</div>
			</Container>
		</section>
	)
}
