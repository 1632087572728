import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/Carousel.module.sass'
import type { ImageResult } from '../queries/ImageFragment'
import { ContemberImage } from './ContemberImage'

type CarouselProps = {
	images: Array<{ id: any; image?: ImageResult; caption?: string }>
	delay?: number
}

export function Carousel(props: CarouselProps) {
	const totalImages = props.images.length ?? 0
	const [activeImageIndex, setActiveImage] = React.useState<number>(1)
	const [initial, setInitial] = React.useState<boolean>(true)

	React.useEffect(() => {
		const timeoutDuration = initial && props.delay ? 4000 + props.delay : 4000
		setInitial(false)

		const timeout = setTimeout(() => {
			if (activeImageIndex < totalImages) {
				setActiveImage((old) => old + 1)
			} else {
				setActiveImage(1)
			}
		}, timeoutDuration)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeImageIndex])

	return (
		<div className={clsx(styles.carousel)}>
			{props.images.map((image, index) => {
				if (image) {
					return (
						<div
							className={clsx(styles.image, index + 1 === activeImageIndex && styles.active)}
							key={image.id}>
							{image.image && <ContemberImage image={image.image} objectFit="cover" />}
						</div>
					)
				}
				return null
			})}
		</div>
	)
}
