/* eslint-disable @typescript-eslint/ban-ts-comment */
import clsx from 'clsx'
import React, { ChangeEvent, ChangeEventHandler, FormEvent, FormEventHandler } from 'react'
import styles from '../app/styles/Form.module.sass'
import { Arrow } from './Arrow.svg'
import { Container } from './Container'
import { Crosss } from './Cross.svg'

export function Form() {
	const [file, setFileState] = React.useState<File | null>(null)
	const handleFile: ChangeEventHandler<HTMLInputElement> = (event: ChangeEvent) => {
		const fileInput = event.target as HTMLInputElement

		if (fileInput.files) {
			setFileState(fileInput.files[0])
		}
	}

	const submitForm: FormEventHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const form = event.currentTarget

		const policy = true //dodelat checkbox

		const data = new FormData(form)

		if (policy && file) {
			const payload = {
				name: data.get('name'),
				surname: data.get('surname'),
				email: data.get('email'),
				fileType: file ? file.type : null,
				fileSize: file ? file.size : null,
				interest: data.get('interest'),
				message: data.get('message'),
				phone: data.get('phone'),
			}

			setFormState('sending')

			const res = await fetch('/api/form', {
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			})

			const result = await res.json()

			if (result.status === 'ok') {
				if (result.file.generateUploadUrl) {
					const u = result.file.generateUploadUrl
					const upload = await fetch(u.url, {
						method: u.method,
						body: file,
						//@ts-ignore
						headers: u.headers.reduce((headers, h) => ({ ...headers, [h.key]: h.value }), {}),
					})

					console.log({
						file,
						publicUrl: u.publicUrl,
						uploadResult: upload,
					})
				}
				setFormState('success')
			} else {
				setFormState('failed')
			}
		} else {
			setFormState('policy-file-failed')
		}
	}

	const [formState, setFormState] = React.useState<null | string>('initial')

	return (
		<div className={clsx(styles.section, 'section')} id="kontakt">
			<Container>
				<h2>Máte zájem o práci? Napište!</h2>
				<div className={styles.inner}>
					{formState !== 'success' && (
						<form
							onSubmit={submitForm}
							className={clsx(styles.form, formState === 'sending' && styles.sending)}>
							<input type="text" name="name" placeholder="Jméno" required></input>
							<input type="text" name="surname" placeholder="Přijmení" required></input>
							<input type="text" name="phone" placeholder="Telefon" required></input>
							<input type="email" name="email" placeholder="E-mail" required></input>
							<input type="text" name="interest" placeholder="Jakou práci hledáte"></input>
							<div className={styles.textareaWrapper}>
								<textarea
									className={styles.textarea}
									onInput={(e) => {
										e.currentTarget.style.minHeight = '0'
										e.currentTarget.style.minHeight = `${e.currentTarget.scrollHeight}px`
									}}
									placeholder="Napište nám něco o vás"
									name="message"></textarea>
							</div>
							<label htmlFor="file">
								{file ? file.name : 'Přiložit CV'}{' '}
								<span>
									<Crosss />
								</span>
							</label>
							<input
								onChange={handleFile}
								className={styles.file}
								id="file"
								type="file"
								name="file"></input>
							<button type="submit">
								ODESLAT <Arrow />
							</button>
						</form>
					)}
					{formState === 'success' && <p>Děkujeme za zprávu, brzy se vám ozveme.</p>}
					{formState === 'policy-file-failed' && <p>Vložte prosím soubor CV.</p>}
				</div>
			</Container>
		</div>
	)
}
