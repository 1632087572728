/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import React, { MouseEventHandler } from 'react'
import styles from '../../app/styles/PersonSlider.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { PersonPopup } from '../PersonPopup'
import { SliderArrow } from '../SliderArrow'

export const personSlider = ({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	const [popup, setPopup] = React.useState<null | number>(null)
	const popupToggler: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
		if (e.currentTarget.dataset.close) {
			setPopup(null)
		} else {
			setPopup(e.currentTarget.dataset.index ? parseInt(e.currentTarget.dataset.index) : null)
		}
	}

	const [currentSlide, setCurrentSlide] = React.useState(0)
	const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
		slidesPerView: 5,
		mode: 'free-snap',
		spacing: 20,
		//centered: true,
		//initial: 1,
		//loop: true,
		breakpoints: {
			'(max-width: 768px)': {
				slidesPerView: 1.5,
				//centered: true,
			},
		},
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide)
		},
	})
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<div className={styles.slider}>
					<SliderArrow
						//@ts-ignore
						onClick={(e) => e.stopPropagation() || slider.prev()}
						disabled={currentSlide === 0}
						isNext={false}
					/>
					<SliderArrow
						//@ts-ignore
						onClick={(e) => e.stopPropagation() || slider.next()}
						disabled={currentSlide === slider?.details().size - 1}
						isNext
					/>
					<div className={clsx(styles.sliderInner, 'keen-slider')} ref={sliderRef}>
						{reference.personList.map((item, index) => (
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events
							<div
								key={index}
								className={clsx(styles.single, 'keen-slider__slide')}
								onClick={popupToggler}
								role="button"
								tabIndex={0}
								data-index={index}>
								<div className={styles.content}>
									<h3>{item.name}</h3>
									<p>{item.position}</p>
								</div>
								<div className={styles.image}>
									{item.media && (
										<ContemberImage
											image={{
												id: item.media.id,
												url: item.media.poster ?? '',
												height: item.media.height,
												width: item.media.width,
											}}
											objectFit={'cover'}
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				{reference.personList.map((item, index) => {
					if (item.media) {
						return (
							<PersonPopup
								isActive={popup == index}
								index={index}
								key={item.id}
								media={item.media}
								description={item.desc ? item.desc : undefined}
								name={item.name ? item.name : undefined}
								position={item.position ? item.position : undefined}
								setPopup={setPopup}
							/>
						)
					}
				})}
			</Container>
		</section>
	)
}
