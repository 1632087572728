import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/BenefitList.module.sass'
import type { BenefitListResultType } from '../queries/ContentQuery'
import { zalomeni } from '../utils/zalomeni'
import { Arrow } from './Arrow.svg'
import { Collapsible } from './Collapsible'

type BenefitListProps = {
	items: BenefitListResultType
}

export function BenefitList(props: BenefitListProps) {
	const [showAll, setShowAll] = React.useState<boolean>(false)
	return (
		<>
			<ul className={styles.wrapper}>
				{props.items.map((item, index) => {
					if (index < 3) {
						return (
							<li className={styles.item} key={item.id}>
								{item.desc && <RichTextRenderer source={zalomeni(item.desc)} />}
							</li>
						)
					}
				})}
				<div className={showAll ? styles.collapsible : ''}>
					<Collapsible expanded={showAll}>
						{props.items.map((item, index) => {
							if (index >= 3) {
								return (
									<li className={styles.item} key={item.id}>
										{item.desc && <RichTextRenderer source={zalomeni(item.desc)} />}
									</li>
								)
							}
						})}
					</Collapsible>
				</div>
			</ul>
			{props.items.length > 3 && (
				<button
					onClick={() => {
						setShowAll((old) => !old)
					}}
					className={clsx(styles.button, showAll ? styles.less : '')}>
					{showAll ? 'Méně' : 'Více'}
					<span>({props.items.length - 3})</span>
					<Arrow />
				</button>
			)}
		</>
	)
}
