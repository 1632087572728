import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import styles from '../app/styles/Button.module.sass'
import type { LinkResultType } from '../queries/ContentQuery'
import { Arrow } from './Arrow.svg'

type LinkProps = {
	link: LinkResultType
	isBtn?: boolean
	arrow?: boolean
	nonExternal?: boolean
	animation?: string
	suffix?: string
	white?: boolean
}

export function Link(props: LinkProps) {
	if (props.link?.externalLink || props.link?.internalLink) {
		const external = Boolean(props.link.type === 'external')
		const url = (
			props.link.internalLink?.url && !external
				? props.link.internalLink.url
				: props.link.externalLink
		) as string
		const anchorLink = url.includes('#')
		return (
			<NextLink href={url}>
				<a
					className={clsx(
						props.isBtn ? styles.btn : styles.link,
						anchorLink && styles.anchor,
						props.white && styles.white
					)}
					data-aos={props.animation ? props.animation : ''}
					target={external && !props.nonExternal && !anchorLink ? '_blank' : ''}>
					{props.link.title + (props.suffix ? ' ' + props.suffix : '')}
					{props.arrow && <Arrow />}
				</a>
			</NextLink>
		)
	} else {
		return null
	}
}
