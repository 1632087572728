import clsx from 'clsx'
import React, { RefObject } from 'react'
import styles from '../app/styles/CookieBar.module.sass'
import { CookieContext } from '../pages/_app'
import { zalomeni } from '../utils/zalomeni'
import { Crosss } from './Cross.svg'

export function CookieBar(props: { title: string; text: string; detailText: string }) {
	// agreed - agreed on everything
	// initial - technical and necessary
	// necessary - technical and necessary
	// analytical - technical and necessary + analytical
	// marketing - technical and necessary + marketing
	const analyticalInput = React.useRef() as RefObject<HTMLInputElement>
	const marketingInput = React.useRef() as RefObject<HTMLInputElement>
	const cookieContext = React.useContext(CookieContext)
	React.useEffect(() => {
		cookieContext?.setCookieLevel(localStorage.getItem('cookieConsent') ?? 'initial')
	}, [])
	React.useEffect(() => {
		localStorage.setItem('cookieConsent', cookieContext.cookieLevel)
		if (cookieContext.cookieLevel.split(',').includes('analytical')) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			gtag('consent', 'update', {
				analytics_storage: 'granted',
			})
		}
		if (cookieContext.cookieLevel.split(',').includes('marketing')) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			gtag('consent', 'update', {
				ad_storage: 'granted',
			})
		}
		if (cookieContext.cookieLevel === 'necessary') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			gtag('consent', 'update', {
				ad_storage: 'denied',
				analytics_storage: 'denied',
			})
		}
	}, [cookieContext])
	return (
		<div
			className={clsx(
				styles.wrapper,
				!cookieContext.cookieLevel.includes('initial') &&
					!cookieContext.cookieLevel.includes('detailedSettings') &&
					styles.solved
			)}>
			<div className={styles.inner}>
				<h2 className={styles.title}>{props.title}</h2>
				<article className={styles.text}>
					{cookieContext.cookieLevel.includes('detailedSettings')
						? zalomeni(props.detailText)
						: zalomeni(props.text)}
				</article>
				{cookieContext.cookieLevel.includes('detailedSettings') && (
					<div className={styles.checkboxes}>
						<label className={styles.checkbox}>
							<input name="technical" className={styles.input} type="checkbox" disabled></input>
							<span>Technické a nutné cookies</span>
							<article className={styles.description}>
								Pomáhají ke správnému fungování webových stránek a k přístupu k jejich zabezpečeným
								částem. Použití těchto cookies není možné odmítnout.
							</article>
						</label>
						<label className={styles.checkbox}>
							<input
								ref={analyticalInput}
								name="analytical"
								className={styles.input}
								type="checkbox"
								checked={cookieContext.cookieLevel.includes('analytical')}
								onClick={() => {
									const levels = cookieContext.cookieLevel.split(',')
									if (levels.includes('analytical')) {
										const index = levels.indexOf('analytical')
										levels.splice(index, 1)
									} else {
										levels.push('analytical')
									}
									cookieContext.setCookieLevel(levels.join(','))
								}}></input>
							<span>Analytické cookies</span>
							<article className={styles.description}>
								Umožňují analyzovat využívání webových stránek jejich návštěvníky, za účelem jejich
								vylepšování. Pomáhají nám získat informace o metrikách, počtu návštěvníků, míře
								okamžitého opuštění, zdroji návštěvnosti atd.
							</article>
						</label>
						<label className={styles.checkbox}>
							<input
								ref={marketingInput}
								name="marketing"
								className={styles.input}
								type="checkbox"
								checked={cookieContext.cookieLevel.includes('marketing')}
								onClick={() => {
									const levels = cookieContext.cookieLevel.split(',')
									if (levels.includes('marketing')) {
										const index = levels.indexOf('marketing')
										levels.splice(index, 1)
									} else {
										levels.push('marketing')
									}
									cookieContext.setCookieLevel(levels.join(','))
								}}></input>
							<span>Marketingové cookies</span>
							<article className={styles.description}>
								Na základě vašich preferencí nám pomáhají nabídnout vám jen taková obchodní sdělení,
								která by vás mohla skutečně zajímat.
							</article>
						</label>
					</div>
				)}
				<div className={styles.actions}>
					{cookieContext.cookieLevel === 'initial' && (
						<>
							<button
								className={styles.button}
								onClick={() => {
									cookieContext.setCookieLevel(cookieContext.cookieLevel + ',detailedSettings')
								}}>
								Detailní nastavení
							</button>
							<button
								className={styles.button}
								onClick={() => {
									cookieContext.setCookieLevel('necessary,analytical,marketing')
								}}>
								Povolit všechny
							</button>
						</>
					)}
					{cookieContext.cookieLevel.includes('detailedSettings') && (
						<button
							className={styles.button}
							onClick={() => {
								if (marketingInput.current?.checked && analyticalInput.current?.checked) {
									cookieContext.setCookieLevel('necessary,analytical,marketing')
									return
								}
								if (marketingInput.current?.checked && !analyticalInput.current?.checked) {
									cookieContext.setCookieLevel('necessary,marketing')
									return
								}
								if (!marketingInput.current?.checked && analyticalInput.current?.checked) {
									cookieContext.setCookieLevel('necessary,analytical')
									return
								}
								cookieContext.setCookieLevel('necessary')
							}}>
							Povolit zvolené
						</button>
					)}
				</div>
				<button
					onClick={() => {
						cookieContext.setCookieLevel('necessary')
					}}
					className={styles.close}>
					<Crosss />
				</button>
			</div>
		</div>
	)
}
