import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/Footer.module.sass'
import { CookieContext } from '../pages/_app'
import type { MenuResult } from '../queries/MenuQuery'
import { Container } from './Container'
import { Facebook } from './Facebook.svg'
import { Instagram } from './Instagram.svg'
import { Link } from './Link'
import { Linkedin } from './Linkedin.svg'
import { Logo } from './Logo.svg'
import { Youtube } from './Youtube.svg'

type FooterProps = {
	menu: MenuResult
	instagram?: string
	facebook?: string
	youtube?: string
	linkedin?: string
}

export function Footer(props: FooterProps) {
	const cookieContext = React.useContext(CookieContext)
	return (
		<div className={clsx(styles.section, 'section')}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.logo}>
						<a href="/">
							<Logo />
						</a>
					</div>
					<ul className={styles.socials}>
						<li>
							<a href={props.youtube}>
								<Youtube />
							</a>
						</li>
						<li>
							<a href={props.instagram}>
								<Instagram />
							</a>
						</li>
						<li>
							<a href={props.facebook}>
								<Facebook />
							</a>
						</li>
						<li>
							<a href={props.linkedin}>
								<Linkedin />
							</a>
						</li>
					</ul>
					<ul className={styles.menu}>
						{props.menu.items.map((item) => (
							<li key={item.id}>
								<Link link={item.link} />
							</li>
						))}
						<li>
							<button
								onClick={() => {
									console.log('lomak')
									const levels = cookieContext.cookieLevel.split(',')
									levels.push('detailedSettings')
									cookieContext.setCookieLevel(levels.join(','))
								}}>
								Nastavení cookies
							</button>
						</li>
					</ul>
					<a className={styles.link} href="https:/www.subterra.cz">
						Subterra.cz
					</a>
				</div>
				<div className={styles.small}>
					<small>Subterra a.s., {new Date().getFullYear()}</small>
					<a href="https://mangoweb.cz">web vytvořil manGoweb</a>
				</div>
			</Container>
		</div>
	)
}
