import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { Content } from '../components/Content'
import { CookieBar } from '../components/CookieBar'
import { Footer } from '../components/Footer'
import { Form } from '../components/Form'
import { Nav } from '../components/Nav'
import { One, Thunder } from '../generated/content'
import { serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'
import { ContentQuery } from '../queries/ContentQuery'
import { LinkFragment } from '../queries/LinkFragment'
import { MenuQuery } from '../queries/MenuQuery'

export default function Page(props: CommonPageProps) {
	const pageContent = props.data.getLinkable?.page?.content
	const menu = props.data.getMenu
	const instagram = props.data.getContact?.instagram
	const facebook = props.data.getContact?.facebook
	const linkedin = props.data.getContact?.linkedin
	const youtube = props.data.getContact?.youtube
	const positions = props.data.listPosition
	const positionsCount = positions ? positions.length : null
	const cookie = props.data.getCookieBar
	return (
		<div className="subterra">
			<Head>
				<title>Subterra - {props.data.getLinkable?.page?.title}</title>
			</Head>
			{menu && (
				<Nav
					menu={menu}
					instagram={instagram}
					facebook={facebook}
					linkedin={linkedin}
					youtube={youtube}
					invert={pageContent?.blocks[0].references[0].type === 'positionList'}
					active={props.data.getLinkable?.page?.id}
					positionsCount={positionsCount ? positionsCount : undefined}
				/>
			)}
			<div className="subterra-content">{pageContent && <Content content={pageContent} />}</div>
			<Form />
			{menu && (
				<Footer
					menu={menu}
					instagram={instagram}
					facebook={facebook}
					linkedin={linkedin}
					youtube={youtube}
				/>
			)}
			{cookie?.title && cookie?.text && (
				<CookieBar
					title={cookie?.title}
					text={cookie?.text}
					detailText="Cookies jsou malé soubory uložené ve Vašem koncovém zařízení, do nichž se ukládají určitá nastavení a data, která si vyměňujete s našimi stránkami prostřednictvím svého prohlížeče. Obsahy těchto souborů jsou sdíleny mezi Vaším prohlížečem a našimi servery, případně se servery našich partnerů. Některé cookies potřebujeme, aby webová stránka mohla správně fungovat, některé potřebujeme pro analytické a marketingové účely."
				/>
			)}
		</div>
	)
}

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

// export const getStaticPaths: GetStaticPaths = async () => {
// 	const content = getZeusConnection(Thunder, 'content')
// 	const dataFromContember = await content.query({
// 		listLinkable: [
// 			{},
// 			{
// 				url: true,
// 			},
// 		],
// 	})

// 	const paths = dataFromContember.listLinkable.map((page) => {
// 		return {
// 			params: {
// 				page: page.url.split('/'),
// 			},
// 		}
// 	})

// 	return { paths: paths, fallback: false }
// }

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const url = context.params?.page ? `${context.params.page}` : ''

	const dataFromContember = await content.query({
		getLinkable: [
			{ by: { url: url } },
			{
				page: [
					{},
					{
						id: true,
						title: true,
						content: [{}, ContentQuery()],
					},
				],
			},
		],
		listPosition: [
			{ filter: { active: { eq: true } } },
			{
				desc: true,
				link: [{}, LinkFragment()],
				id: true,
				place: true,
				short: true,
				title: true,
				active: true,
			},
		],
		getContact: [
			{ by: { unique: One.One } },
			{
				instagram: true,
				facebook: true,
				linkedin: true,
				youtube: true,
			},
		],
		getCookieBar: [
			{ by: { unique: One.One } },
			{
				title: true,
				text: true,
			},
		],
		getMenu: [{ by: { unique: One.One } }, MenuQuery()],
	})

	return { props: { params: context.params, data: dataFromContember } }
})
