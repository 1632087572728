import type { ReferenceRendererProps } from '@contember/react-client'
import styles from '../../app/styles/Header.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { Link } from '../Link'
import { Media } from '../Media'

export const header = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<header className={styles.header}>
			<Container>
				<div className={styles.inner}>
					{reference.primaryText && <h1>{zalomeni(reference.primaryText)}</h1>}
					<article>{children}</article>
					<Link link={reference.link} isBtn arrow white />
					<Link link={reference.secondaryLink} isBtn arrow white />
				</div>
			</Container>
			<Media media={reference.media} cover />
		</header>
	)
}
