/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/CtaSection.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Cta } from '../Cta'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const cta = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	const desc = children as unknown as string
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<div className={styles.bg}>
				{reference.image && <ContemberImage image={reference.image} objectFit="cover" />}
			</div>
			<Container>
				<Cta
					headline={reference.primaryText ? zalomeni(reference.primaryText) : ''}
					desc={desc}
					link={reference.link}
					autoHeight
					bigger
					offset={reference.ctaView !== 'without_image'}
					transparent={reference.ctaView === 'without_image'}
				/>
			</Container>
		</section>
	)
}
