/* eslint-disable react-hooks/rules-of-hooks */
import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/Numbers.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Carousel } from '../Carousel'
import { Container } from '../Container'
import { Link } from '../Link'
import { Triangle } from '../Triangle.svg'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const numbers = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	const [numbersTab, setNumbersTab] = React.useState<number>(0)

	const toggleTab: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> =
		React.useCallback((e) => {
			e.preventDefault()

			const tabNum = e.currentTarget.dataset.index ? parseInt(e.currentTarget.dataset.index) : 0

			setNumbersTab(tabNum)
		}, [])

	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<div className={styles.inner}>
					<ul className={styles.nav}>
						{reference.numberList?.items.map((item, index) => (
							<li key={item.id} className={numbersTab == index ? styles.active : ''}>
								<button data-index={index} onClick={toggleTab}>
									{item.number}
								</button>
								<span>{item.text}</span>
								<Triangle />
							</li>
						))}
					</ul>
					<div className={styles.content}>
						{reference.numberList?.items.map((item, index) => {
							const images = item.images?.items
							return (
								<div
									className={clsx(styles.single, index == numbersTab && styles.active)}
									key={item.id}>
									<article>
										{item.desc && <RichTextRenderer source={zalomeni(item.desc)} />}
										<br></br>
										<Link link={item.link} arrow />
									</article>
									{images && <Carousel images={images} />}
								</div>
							)
						})}
					</div>
				</div>
			</Container>
		</section>
	)
}
