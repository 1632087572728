import clsx from 'clsx'
import React, { MouseEventHandler } from 'react'
import styles from '../app/styles/SliderArrow.module.sass'
import { Arrow } from './Arrow.svg'

type SliderArrowProps = {
	disabled: boolean
	onClick: MouseEventHandler<HTMLButtonElement>
	isNext: boolean
}

export function SliderArrow(props: SliderArrowProps) {
	const disabeld = props.disabled ? 'arrow--disabled' : ''
	return (
		<button
			className={clsx(styles.button, disabeld, props.isNext && styles.next)}
			onClick={props.onClick}>
			<Arrow />
		</button>
	)
}
