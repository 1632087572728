export function Arrow() {
	return (
		<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.2699 0.832275L18.3692 9.3322L11.2361 17.4358L8.23361 14.7929L11.3099 11.298H0.734863V7.29803H11.4586L8.19985 3.39644L11.2699 0.832275Z"
				fill="currentColor"
			/>
		</svg>
	)
}
