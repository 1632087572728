import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/IntermingleBox.module.sass'
import type { IntermingleBoxResult } from '../queries/IntermingleBoxFragment'
import { ContemberImage } from './ContemberImage'

type IntermingleBoxProps = {
	box: IntermingleBoxResult
	delay?: number
	smaller?: boolean
}

export function IntermingleBox(props: IntermingleBoxProps) {
	const totalImages = props.box.images?.items.length ?? 0
	const [activeImageIndex, setActiveImage] = React.useState<number>(1)
	const [initial, setInitial] = React.useState<boolean>(true)

	React.useEffect(() => {
		const timeoutDuration = initial && props.delay ? 4000 + props.delay : 4000
		setInitial(false)

		const timeout = setTimeout(() => {
			if (activeImageIndex < totalImages) {
				setActiveImage((old) => old + 1)
			} else {
				setActiveImage(1)
			}
		}, timeoutDuration)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeImageIndex])

	return (
		<div className={clsx(styles.box, props.smaller && styles.smaller)}>
			<div className={styles.content}>
				{props.box.images?.items.map((image, index) => {
					if (image.image) {
						return (
							<h3 className={index + 1 === activeImageIndex ? styles.active : ''}>{image.title}</h3>
						)
					}
					return null
				})}
			</div>
			{props.box.images?.items.map((image, index) => {
				if (image.image) {
					return (
						<div
							className={clsx(styles.image, index + 1 === activeImageIndex && styles.active)}
							key={image.id}>
							<ContemberImage image={image.image} objectFit="cover" />
						</div>
					)
				}
				return null
			})}
		</div>
	)
}
