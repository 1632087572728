import clsx from 'clsx'
import React from 'react'
import styles from '../app/styles/Box.module.sass'
import type { BoxResult } from '../queries/BoxFragment'
import { Link } from './Link'

type BoxProps = {
	box: BoxResult
}

export function Box(props: BoxProps) {
	const bg =
		props.box.bg === 'grey' ? styles.grey : props.box.bg === 'yellow' ? styles.yellow : styles.white
	return (
		<div className={clsx(styles.box, bg)}>
			<div className={styles.content}>
				<h3>{props.box.title}</h3>
				<article>{props.box.desc}</article>
				<Link link={props.box.link} arrow />
			</div>
		</div>
	)
}
