/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/rules-of-hooks */
import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React, { MouseEventHandler, RefObject } from 'react'
import styles from '../../app/styles/CareerPaths.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Arrow } from '../Arrow.svg'
import { Collapsible } from '../Collapsible'
import { Container } from '../Container'
import { Crosss } from '../Cross.svg'

export const careerPath = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	const infoboxRef = React.useRef() as RefObject<HTMLDivElement>
	const containerRef = React.useRef() as RefObject<HTMLDivElement>
	const [showAll, setShowAll] = React.useState<boolean>(false)
	const [infoBoxContent, setInfoBoxContent] = React.useState<string | null>(null)
	const [infoBoxVisibility, setInfoBoxVisibility] = React.useState<{
		visible: boolean
		x: string
		y: string
	}>({ visible: false, x: '0px', y: '0px' })
	const handlePositionClick: MouseEventHandler<HTMLDivElement> = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		const currentPositionEl = event.currentTarget
		const wrapper = containerRef.current as HTMLDivElement

		const elRect = currentPositionEl.getBoundingClientRect()
		const wrapperRect = wrapper.getBoundingClientRect()
		const xMiddle = elRect.x - wrapperRect.x + elRect.width / 2
		const yMiddle = elRect.y - wrapperRect.y + elRect.height / 2

		if (currentPositionEl.dataset.desc) {
			setInfoBoxContent(currentPositionEl.dataset.desc)
			if (window.matchMedia('(max-width: 480px)').matches) {
				setInfoBoxVisibility({ visible: true, x: '50%', y: '50%' })
			} else {
				setInfoBoxVisibility({ visible: true, x: `${xMiddle}px`, y: `${yMiddle}px` })
			}
		} else {
			setInfoBoxVisibility({ visible: false, x: '0px', y: '0px' })
		}
		const handleClickOutside = (event: MouseEvent) => {
			const clickedEl = event.target as Node | null
			if (infoboxRef.current && !infoboxRef.current.contains(clickedEl)) {
				console.log('uvnitr')
				setInfoBoxVisibility({ visible: false, x: '0px', y: '0px' })
				document.removeEventListener('mousedown', handleClickOutside)
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
	}
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<article>{children}</article>
				<div ref={infoboxRef} className={styles.inner}>
					{infoBoxVisibility.visible && (
						<div
							className={styles.info}
							style={{ top: infoBoxVisibility.y, left: infoBoxVisibility.x }}>
							<button
								onClick={() => {
									setInfoBoxVisibility({ visible: false, x: '0px', y: '0px' })
								}}>
								<Crosss />
							</button>
							<article>
								{infoBoxContent && <RichTextRenderer source={zalomeni(infoBoxContent)} />}
							</article>
						</div>
					)}
					<div ref={containerRef} className={styles.innerScrollable}>
						{reference.positionChainList.map((item, index) => {
							if (index < 3) {
								return (
									<div className={styles.chain}>
										{item.items.map((pos) => (
											<div
												className={styles.single}
												key={pos.id}
												onClick={handlePositionClick}
												data-desc={pos.position?.desc}>
												<p>{pos.position?.title}</p>
											</div>
										))}
									</div>
								)
							}
						})}
						<div className={showAll ? styles.collapsible : ''}>
							<Collapsible expanded={showAll}>
								{reference.positionChainList.map((item, index) => {
									if (index >= 3) {
										return (
											<div className={styles.chain}>
												{item.items.map((pos) => (
													<div
														className={styles.single}
														key={pos.id}
														onClick={handlePositionClick}
														data-desc={pos.position?.desc}>
														<p>{pos.position?.title}</p>
													</div>
												))}
											</div>
										)
									}
								})}
							</Collapsible>
						</div>
					</div>
				</div>
				{reference.positionChainList.length > 3 && (
					<button
						onClick={() => {
							setShowAll((old) => !old)
						}}
						className={clsx(styles.button, showAll ? styles.less : '')}>
						{showAll ? 'MÉNĚ KARIÉRNÍCH CEST' : 'VÍCE KARIERNÍCH CEST'}
						<Arrow />
					</button>
				)}
			</Container>
		</section>
	)
}
