import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/Contact.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Facebook } from '../Facebook.svg'
import { Instagram } from '../Instagram.svg'
import { Link } from '../Link'
import { Linkedin } from '../Linkedin.svg'
import { Youtube } from '../Youtube.svg'

export const contact = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	console.log(reference, children)
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<div className={styles.bg}>
				{reference.contact?.image && (
					<ContemberImage image={reference.contact?.image} objectFit="cover" />
				)}
			</div>
			<div className={styles.header}>
				{reference.contact?.image && (
					<ContemberImage image={reference.contact?.image} objectFit="cover" />
				)}
				<Container>
					{reference.contact?.headline && <h1>{zalomeni(reference.contact?.headline)}</h1>}
				</Container>
			</div>
			<Container>
				<div className={styles.inner}>
					<div className={styles.box}>
						<div className={styles.boxInner}>
							{reference.contact && reference.contact.formHeadline && (
								<h3>{zalomeni(reference.contact.formHeadline)}</h3>
							)}
							<Link link={reference.contact?.formLink} arrow />
						</div>
					</div>
					<div className={styles.box}>
						<div className={styles.boxInner}>
							<h3>{reference.contact?.contactPersonHeadline}</h3>
							<article>
								{reference.contact?.contactPersonDesc && (
									<RichTextRenderer source={zalomeni(reference.contact?.contactPersonDesc)} />
								)}
							</article>
						</div>
					</div>
					<div className={styles.box}>
						<div className={styles.boxInner}>
							{reference.contact && reference.contact.socialsHeadline && (
								<h3>{zalomeni(reference.contact?.socialsHeadline)}</h3>
							)}
							<ul>
								<li>
									<a href={reference.contact?.youtube}>
										<Youtube />
									</a>
								</li>
								<li>
									<a href={reference.contact?.instagram}>
										<Instagram />
									</a>
								</li>
								<li>
									<a href={reference.contact?.facebook}>
										<Facebook />
									</a>
								</li>
								<li>
									<a href={reference.contact?.linkedin}>
										<Linkedin />
									</a>
								</li>
							</ul>
						</div>
					</div>
					{reference.contact?.newsletterHeadline && (
						<div className={styles.box}>
							<div className={styles.boxInner}>
								<h3>{zalomeni(reference.contact?.newsletterHeadline)}</h3>
								<Link link={reference.contact?.newsletterLink} arrow />
							</div>
						</div>
					)}
				</div>
			</Container>
		</section>
	)
}
