import clsx from 'clsx'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import styles from '../app/styles/Collapsible.module.sass'
import { forceReflow } from '../utils/forceReflow'

export type CollapsibleTransition =
	| 'topInsert'
	| 'leftInsert'
	| 'rightInsert'
	| 'bottomInsert'
	| 'fade'

export interface CollapsibleProps {
	expanded: boolean
	transition?: CollapsibleTransition
	children?: ReactNode
	onClose?: () => void
	onOpen?: () => void
	onTransitionEnd?: () => void
}

export const Collapsible = (props: CollapsibleProps) => {
	const contentRef = useRef<HTMLDivElement>(null)
	const [isTransitioning, setIsTransitioning] = useState(false)
	const [contentHeight, setContentHeight] = useState('auto')
	const [delayedExpanded, setDelayedExpanded] = useState(props.expanded)

	const onTransitionEnd = () => {
		if (!isTransitioning) {
			return
		}

		setContentHeight('auto')
		setIsTransitioning(false)
		props.onTransitionEnd?.()
		props.expanded ? props.onOpen?.() : props.onClose?.()
	}

	const updateContentHeight = () => {
		if (contentRef.current) {
			const contentHeight = `${contentRef.current.getBoundingClientRect().height}px`
			setContentHeight(contentHeight)
		}
	}

	useEffect(() => {
		let isMounted = true
		if (props.expanded !== delayedExpanded) {
			setIsTransitioning(true)
			updateContentHeight()
			requestAnimationFrame(() => {
				if (!isMounted) {
					return
				}
				forceReflow(contentRef.current)
				setDelayedExpanded(props.expanded)
			})
		}
		return () => {
			isMounted = false
		}
	}, [delayedExpanded, props.expanded])

	return (
		<div
			className={clsx(
				styles.collapsible,
				styles[`view_${props.transition ?? 'topInsert'}`],
				delayedExpanded && styles.is_expanded,
				isTransitioning && styles.is_transitioning
			)}
			style={
				{
					'--cui-collapsible-content-height': contentHeight,
				} as CSSProperties // Custom properties not supported workaround
			}
			aria-hidden={!props.expanded}
			onTransitionEnd={onTransitionEnd}>
			<div className={styles.content} ref={contentRef}>
				{props.children}
			</div>
		</div>
	)
}
Collapsible.displayName = 'Collapsible'
