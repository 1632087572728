/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */

import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/Schools.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { Link } from '../Link'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const schools = ({ children, reference }: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
				<article>{children}</article>
				<div className={styles.inner}>
					<ul>
						{reference.schoolList.map((item) => (
							<li key={item.id}>
								<h4>{item.name}</h4>
								<p>{item.desc && <RichTextRenderer source={zalomeni(item.desc)} />}</p>
								<Link link={item.link} />
							</li>
						))}
					</ul>
				</div>
			</Container>
		</section>
	)
}
