import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import styles from '../../app/styles/TextAndBoxes.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Box } from '../Box'
import { Container } from '../Container'
import { IntermingleBox } from '../IntermingleBox'

export const textAndBoxes = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section
			className={clsx(
				styles.section,
				'section',
				reference.bg,
				reference.textAndBoxesView === 'right' && styles.viewRight,
				reference.textAndBoxesView === 'left_both' && styles.viewBoth
			)}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.left}>
						{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
						<article>{children}</article>
					</div>
					<div className={styles.right}>
						{reference.intermingleBoxList?.items.map((item, index) => {
							if (index < 2) {
								return <IntermingleBox box={item} key={item.id} delay={index * 1000} />
							}
						})}
					</div>
					<div className={styles.bottom}>
						{reference.intermingleBoxList?.items.map((item, index) => {
							if (index >= 2) {
								return <IntermingleBox box={item} key={item.id} delay={index * 1000} smaller />
							}
						})}
						{reference.boxList?.items.map((item) => (
							<Box box={item} key={item.id} />
						))}
					</div>
				</div>
			</Container>
		</section>
	)
}
