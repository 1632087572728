import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import styles from '../app/styles/Cta.module.sass'
import type { LinkResultType } from '../queries/ContentQuery'
import { zalomeni } from '../utils/zalomeni'
import { Link } from './Link'

type CtaProps = {
	headline: string
	desc: string | ReactElement
	link: LinkResultType
	autoHeight?: boolean
	bigger?: boolean
	offset?: boolean
	transparent?: boolean
}

export function Cta(props: CtaProps) {
	return (
		<div
			className={clsx(
				styles.cta,
				props.autoHeight && styles.auto,
				props.bigger && styles.bigger,
				props.offset && styles.offset,
				props.transparent && styles.transparent
			)}>
			<div className={styles.inner}>
				<h3>{props.headline}</h3>
				<article>
					{typeof props.desc == 'string' ? (
						<RichTextRenderer source={zalomeni(props.desc)} />
					) : (
						props.desc
					)}
				</article>
				<Link link={props.link} arrow isBtn={props.transparent} nonExternal />
			</div>
		</div>
	)
}
