import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import styles from '../../app/styles/TextAndImage.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'

export const textAndImage = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.content}>
						{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
						<article>{children}</article>
						{reference.link && <Link isBtn arrow link={reference.link} />}
						{reference.secondaryLink && <Link isBtn arrow link={reference.secondaryLink} />}
					</div>
					<div className={clsx(styles.image, reference.smallerImage && styles.smaller)}>
						{reference.image && <ContemberImage image={reference.image} objectFit="cover" />}
					</div>
				</div>
			</Container>
		</section>
	)
}
