import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import React from 'react'
import styles from '../../app/styles/TextAndGallery.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import type { ImageResult } from '../../queries/ImageFragment'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { ContemberImage } from '../ContemberImage'
import { Link } from '../Link'

export const textAndGallery = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				<div className={styles.inner}>
					<div className={styles.content}>
						{reference.primaryText && <h2>{zalomeni(reference.primaryText)}</h2>}
						<article>{children}</article>
						{reference.link && <Link isBtn arrow link={reference.link} />}
						{reference.secondaryLink && <Link isBtn arrow link={reference.secondaryLink} />}
					</div>
					{reference.gallery?.items && <Gallery images={reference.gallery?.items} />}
				</div>
			</Container>
		</section>
	)
}

type GalleryProps = {
	images: Array<{ id: any; image?: ImageResult; caption?: string }>
}

const Gallery = (props: GalleryProps) => {
	const totalImages = props.images.length ?? 0
	const [activeImageIndex, setActiveImage] = React.useState<number>(1)

	React.useEffect(() => {
		const timeoutDuration = 4000

		const timeout = setTimeout(() => {
			if (activeImageIndex < totalImages) {
				setActiveImage((old) => old + 1)
			} else {
				setActiveImage(1)
			}
		}, timeoutDuration)

		return () => {
			clearTimeout(timeout)
		}
	}, [activeImageIndex])

	return (
		<div className={styles.gallery}>
			<div className={styles.top}>
				{props.images.map((item, index) => (
					<div
						className={clsx(styles.image, index + 1 === activeImageIndex && styles.active)}
						key={item.id}>
						{item.image && <ContemberImage image={item.image} objectFit="cover" />}
					</div>
				))}
			</div>
			{props.images.map((item, index) => (
				<div
					className={clsx(styles.image, index + 1 === activeImageIndex && styles.active)}
					key={item.id}>
					{item.image && <ContemberImage image={item.image} objectFit="cover" />}
				</div>
			))}
		</div>
	)
}
