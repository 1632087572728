import { RichTextRenderer } from '@contember/react-client'
import React from 'react'
import type { ContentResult } from '../queries/ContentQuery'
import { zalomeni } from '../utils/zalomeni'
import * as referenceRenderers from './referenceRenderers'

export interface ContentProps {
	content: ContentResult
}

export const Content = (props: ContentProps) => {
	return (
		<RichTextRenderer
			blocks={props.content.blocks}
			sourceField="json"
			referenceRenderers={referenceRenderers}
			renderLeaf={React.useCallback((f) => {
				if (typeof f.children === 'string') {
					return <>{zalomeni(f.children)}</>
				}
				return f.fallback
			}, [])}
		/>
	)
}
