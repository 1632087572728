/* eslint-disable @typescript-eslint/no-unused-vars */

import type { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import styles from '../../app/styles/Positions.module.sass'
import type { ContentBlockReference } from '../../queries/ContentQuery'
import { zalomeni } from '../../utils/zalomeni'
import { Container } from '../Container'
import { Link } from '../Link'

export const positionList = ({
	children,
	reference,
}: ReferenceRendererProps<ContentBlockReference>) => {
	return (
		<section className={clsx(styles.section, 'section', reference.bg)}>
			<Container>
				{reference.primaryText && <h1>{zalomeni(reference.primaryText)}</h1>}
				<div className={styles.inner}>
					<ul>
						{reference.positionList.map((item) => (
							<li key={item.id}>
								<h4>{item.title}</h4>
								<p>{item.short}</p>
								<p>{item.place}</p>
								<Link link={item.link} arrow />
							</li>
						))}
					</ul>
				</div>
			</Container>
		</section>
	)
}
