export function Play() {
	return (
		<svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 27.2009V2.51258C0 0.962301 1.68751 0.00146151 3.0207 0.792649L22.3085 12.2391C23.5645 12.9845 23.6239 14.7811 22.4198 15.6078L3.13199 28.8497C1.80488 29.7609 0 28.8107 0 27.2009Z"
				fill="black"
			/>
		</svg>
	)
}
